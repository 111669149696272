 <template>
  <div>
    <new-password />
  </div>
</template>

<script>
import NewPassword from '@/components/Auth/NewPassword.vue'
export default {
  name: 'recovery',
  components: {
    'new-password': NewPassword
  }
}
</script>
